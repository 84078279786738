<template>
  <div>
    <div class="card">
      <div class="card-body">
        <h5 class="mb-4">
          <strong>Ubah Data Pasien</strong>
        </h5>
        <a-form :form="form" @submit="findPatient">
          <a-form-item
            :label-col="labelCol"
            :wrapper-col="wrapperCol"
            label="Kode Pasien"
          >
            <a-input
              :disabled="submitting"
              placeholder="Masukkan Kode Pasien"
              v-decorator="[
                'code',
                {
                  rules: [
                    { required: true, message: 'Kode Pasien harus diisi!' },
                  ],
                },
              ]"
            />
            <button
              type="submit"
              class="btn btn-success px-5"
              :disabled="submitting"
            >
              Cari Pasien
            </button>
          </a-form-item>
        </a-form>
      </div>
    </div>
    <div class="card" v-if="patientId">
      <div class="card-body">
        <h5 class="mb-4">
          <strong>Data Pasien</strong>
        </h5>
        <a-form :form="form" @submit="handleSubmit">
          <a-form-item
            :label-col="labelCol"
            :wrapper-col="wrapperCol"
            label="Nama"
          >
            <a-input
              :disabled="submitting"
              placeholder="Nama"
              v-decorator="['fullname']"
            />
          </a-form-item>
          <!-- v-decorator="['fullname', {rules: [{ required: true, message: 'Nama harus diisi!' }]}]" -->
          <a-form-item
            :label-col="labelCol"
            :wrapper-col="wrapperCol"
            label="Tanggal Lahir"
          >
            <a-date-picker
              :disabled="submitting"
              v-decorator="['dateOfBirth']"
            />
          </a-form-item>
          <a-form-item
            :label-col="labelCol"
            :wrapper-col="wrapperCol"
            label="Nomor KTP"
          >
            <a-input
              :disabled="submitting"
              placeholder="Nomor KTP"
              v-decorator="['idNumber']"
            />
          </a-form-item>
          <a-form-item
            :label-col="labelCol"
            :wrapper-col="wrapperCol"
            label="Kode Pasien"
          >
            <a-input
              :disabled="submitting"
              placeholder="Kode Pasien"
              v-decorator="[
                'code',
                {
                  rules: [
                    { required: true, message: 'Kode Pasien harus diisi!' },
                  ],
                },
              ]"
            />
          </a-form-item>
          <a-form-item
            :label-col="labelCol"
            :wrapper-col="wrapperCol"
            label="Jenis Kelamin"
          >
            <a-select
              :disabled="submitting"
              v-decorator="['gender']"
              placeholder="Jenis Kelamin"
            >
              <a-select-option
                v-for="gender in genderList"
                :key="gender.value"
                :value="gender.value"
              >
                {{ gender.name }}
              </a-select-option>
            </a-select>
          </a-form-item>
          <a-form-item
            :label-col="labelCol"
            :wrapper-col="wrapperCol"
            label="Kota"
          >
            <a-select
              :disabled="submitting"
              v-decorator="['city']"
              placeholder="Kota"
            >
              <a-select-option
                v-for="city in cityList"
                :key="city.id"
                :value="city.name"
              >
                {{ city.name }}
              </a-select-option>
            </a-select>
          </a-form-item>
          <a-form-item
            :label-col="labelCol"
            :wrapper-col="wrapperCol"
            label="E-mail"
          >
            <a-input
              :disabled="submitting"
              placeholder="E-mail"
              v-decorator="['email']"
            />
          </a-form-item>
          <a-form-item
            :label-col="labelCol"
            :wrapper-col="wrapperCol"
            label="Nama Perwakilan"
          >
            <a-input
              :disabled="submitting"
              placeholder="Nama Perwakilan"
              v-decorator="['representativeName']"
            />
          </a-form-item>
          <a-form-item
            :label-col="labelCol"
            :wrapper-col="wrapperCol"
            label="Nomor Telepon Perwakilan"
          >
            <a-input
              :disabled="submitting"
              placeholder="Nomor Telepon Perwakilan"
              v-decorator="['representativePhone']"
            />
          </a-form-item>
          <a-form-item
            :label-col="labelCol"
            :wrapper-col="wrapperCol"
            label="Hubungan Perwakilan"
          >
            <a-input
              :disabled="submitting"
              placeholder="Hubungan Perwakilan"
              v-decorator="['representativeRelationship']"
            />
          </a-form-item>
          <a-form-item
            :label-col="labelCol"
            :wrapper-col="wrapperCol"
            label="Foto KTP"
          >
            <a-upload
              :multiple="false"
              v-decorator="[
                'image',
                {
                  valuePropName: 'image',
                },
              ]"
              name="upload"
              :action="action"
              list-type="picture"
              :file-list="fileList"
              @change="handleChange"
            >
              <a-button> <a-icon type="upload" /> Click to upload </a-button>
            </a-upload>
          </a-form-item>
          <a-form-item
            :label-col="labelCol"
            :wrapper-col="wrapperCol"
            label="Foto Selfie"
          >
            <a-upload
              :multiple="false"
              v-decorator="[
                'image2',
                {
                  valuePropName: 'image2',
                },
              ]"
              name="upload"
              :action="action2"
              list-type="picture"
              :file-list="fileList2"
              @change="handleChange2"
            >
              <a-button> <a-icon type="upload" /> Click to upload </a-button>
            </a-upload>
          </a-form-item>
          <button
            type="submit"
            class="btn btn-success px-5"
            :disabled="submitting"
          >
            Simpan Data
          </button>
          <button
            class="btn btn-primary px-5 m-1"
            :disabled="submitting"
            @click="
              (e) => {
                e.preventDefault()
                setModalVisible2(true)
              }
            "
          >
            Reset Password
          </button>
          <button
            class="btn btn-danger px-5 m-1"
            :disabled="submitting"
            @click="
              (e) => {
                e.preventDefault()
                setModalVisible(true)
              }
            "
          >
            Hapus Data
          </button>
          <button
            class="btn btn-secondary px-5"
            :disabled="submitting"
            @click="handleCancel"
          >
            Kembali
          </button>
        </a-form>
      </div>
    </div>
    <a-modal
      title="Konfirmasi"
      :dialog-style="{ top: '20px' }"
      :visible="modalVisible2"
      @ok="() => handleResetPassword()"
      @cancel="() => setModalVisible2(false)"
    >
      Apakah Anda yakin akan mereset password pasien
      {{ form.getFieldValue('fullname') }}? <br />
      email akan dikirimkan ke {{ form.getFieldValue('email') }}
    </a-modal>
    <a-modal
      title="Konfirmasi"
      :dialog-style="{ top: '20px' }"
      :visible="modalVisible"
      @ok="() => handleDelete()"
      @cancel="() => setModalVisible(false)"
    >
      Apakah Anda yakin akan menghapus data pasien
      {{ form.getFieldValue('fullname') }}?
    </a-modal>
  </div>
</template>
<script>
import router from '@/router'
import { getByCode, editById, deleteById } from '@/services/axios/api/pasien'
import { getAll as getAllCity } from '@/services/axios/api/city'
import { resetPassword } from '@/services/axios/api/auth'
import { BASE_URL } from '@/config'
import { notification } from 'ant-design-vue'

const labelCol = {
  xs: { span: 24 },
  sm: { span: 5 },
}
const wrapperCol = {
  xs: { span: 24 },
  sm: { span: 12 },
}
export default {
  data: function () {
    return {
      form: this.$form.createForm(this),
      labelCol,
      wrapperCol,
      cityList: [],
      genderList: [
        { name: 'Pria', value: 'male' },
        { name: 'Wanita', value: 'female' },
      ],
      submitting: false,
      patientId: '',
      action: `${BASE_URL}/upload/id`,
      // action: `${BASE_URL}/upload/news`,
      action2: `${BASE_URL}/upload/selfie`,
      // action2: `${BASE_URL}/upload/news`,
      fileList: [],
      fileList2: [],
      modalVisible2: false,
      modalVisible: false,
    }
  },
  created() {
    this.getCityList()
    this.$store.state.patientData = []
  },
  methods: {
    async getCityList() {
      const res = await getAllCity()
      this.cityList = res
    },
    findPatient(e) {
      this.patientId = ''
      this.fileList = []
      this.fileList2 = []
      e.preventDefault()
      this.form.validateFields(async (err, values) => {
        if (!err) {
          this.submitting = true
          console.log('Received values of form: ', values)
          try {
            const res = await getByCode(values.code)
            this.form.getFieldDecorator('fullname', { initialValue: '' })
            this.form.getFieldDecorator('dateOfBirth', { initialValue: null })
            this.form.getFieldDecorator('idNumber', { initialValue: '' })
            this.form.getFieldDecorator('gender', { initialValue: '' })
            this.form.getFieldDecorator('city', { initialValue: '' })
            this.form.getFieldDecorator('email', { initialValue: '' })
            this.form.getFieldDecorator('code', { initialValue: '' })
            this.form.getFieldDecorator('representativeName', {
              initialValue: '',
            })
            this.form.getFieldDecorator('representativePhone', {
              initialValue: '',
            })
            this.form.getFieldDecorator('representativeRelationship', {
              initialValue: '',
            })
            this.form.getFieldDecorator('image', { initialValue: '' })
            this.form.getFieldDecorator('image2', { initialValue: '' })
            this.patientId = res.id
            this.form.setFieldsValue({ fullname: res.fullname })
            this.form.setFieldsValue({ dateOfBirth: res.dateOfBirth })
            this.form.setFieldsValue({ idNumber: res.idNumber })
            this.form.setFieldsValue({ gender: res.gender })
            this.form.setFieldsValue({ city: res.city })
            this.form.setFieldsValue({ email: res.email })
            this.form.setFieldsValue({ code: res.code })
            this.form.setFieldsValue({
              representativeName: res.representativeName,
            })
            this.form.setFieldsValue({
              representativePhone: res.representativePhone,
            })
            this.form.setFieldsValue({
              representativeRelationship: res.representativeRelationship,
            })
            this.form.setFieldsValue({
              image: { file: { response: { data: res.idPicture } } },
            })
            this.form.setFieldsValue({
              image2: { file: { response: { data: res.selfiePicture } } },
            })
            this.fileList[0] = {
              uid: '-1',
              name: res.idPicture.split('/')[
                res.idPicture.split('/').length - 1
              ],
              status: 'done',
              url: `${BASE_URL}${res.idPicture}`,
              // url: `${BASE_URL}${res.idPicture}`,
            }
            this.fileList2[0] = {
              uid: '-1',
              name: res.selfiePicture.split('/')[
                res.selfiePicture.split('/').length - 1
              ],
              status: 'done',
              url: `${BASE_URL}${res.selfiePicture}`,
              // url: `${BASE_URL}${res.selfiePicture}`,
            }
            console.log(res)
            this.submitting = false
          } catch (e) {
            this.submitting = false
          }
        }
      })
    },
    handleChange(info) {
      let fileList = [...info.fileList]
      fileList = fileList.slice(-1)
      fileList = fileList.map((file) => {
        if (file.response) {
          file.url = file.response.url
        }
        return file
      })

      this.fileList = fileList
    },
    handleChange2(info) {
      let fileList2 = [...info.fileList]
      fileList2 = fileList2.slice(-1)
      fileList2 = fileList2.map((file) => {
        if (file.response) {
          file.url = file.response.url
        }
        return file
      })

      this.fileList2 = fileList2
    },
    handleSubmit(e) {
      e.preventDefault()
      this.form.validateFields(async (err, values) => {
        if (!err) {
          this.submitting = true
          try {
            values.idPicture = values.image.file.response.data
            values.selfiePicture = values.image2.file.response.data
            values.image = null
            values.image2 = null
            console.log('Received values of form: ', values)
            const res = await editById(this.patientId, values)
            console.log(res)
            if (res && res.data) {
              notification.success({
                message: 'Sukses',
                description: 'Data berhasil disimpan',
                duration: 5,
              })
              router.push('/pasien')
            }
            this.submitting = false
          } catch (e) {
            this.submitting = false
          }
        } else {
          console.log(err)
          console.log(values)
        }
      })
    },
    setModalVisible(modalVisible) {
      this.modalVisible = modalVisible
    },
    setModalVisible2(modalVisible2) {
      this.modalVisible2 = modalVisible2
    },
    async handleResetPassword() {
      this.form.validateFields(async (err, values) => {
        if (!err) {
          this.submitting = true
          console.log('Received values of form: ', values)
          try {
            const res = await resetPassword(values, 'patient')
            if (res && res.data) {
              notification.success({
                message: 'Sukses',
                description:
                  'Password berhasil direset, silakan cek email terdaftar',
                duration: 5,
              })
            } else {
              notification.warning({
                message: 'Gagal',
                description: 'Password gagal direset',
                duration: 5,
              })
            }
            this.submitting = false
          } catch (e) {
            this.submitting = false
          }
          this.modalVisible2 = false
        }
      })
    },
    async handleDelete() {
      const res = await deleteById(this.patientId)
      if (res && res.data) {
        notification.success({
          message: 'Sukses',
          description: 'Data berhasil dihapus',
          duration: 5,
        })
        router.push('/pasien')
      } else {
        notification.warning({
          message: 'Gagal',
          description: 'Data gagal dihapus',
          duration: 5,
        })
      }
      this.submitting = false
      this.modalVisible = false
    },
    handleCancel(e) {
      e.preventDefault()
      router.push('/pasien')
    },
  },
}
</script>
